import {CategoryList, CategoryContent} from './Category';
import {ItemEdit} from './Item';
import Footer from './Footer';
import {useEffect, useState} from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

function App() {
  const [isAdmin, setIsAdmin] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    getUserInfo();
  }, []);

  async function getUserInfo() {
    try {
        const response = await fetch('/.auth/me');
        const payload = await response.json();
        const { clientPrincipal } = payload;
        
        if(clientPrincipal){
          setUser(clientPrincipal);
          if (clientPrincipal.userRoles.includes("admin"))
            setIsAdmin(true);
          console.log(`clientPrincipal = ${JSON.stringify(clientPrincipal)}`);
        } 
        
    } catch (error) {
        console.error('No profile could be found ' + error?.message?.toString());
    }
  };  

  return (
    <Router>
    <h1>I miei barlesc</h1>
    <Routes>
      <Route path="/" element={
        <CategoryList admin={isAdmin}/>
      } />
      <Route path="/category/:categoryId" element={
        <CategoryContent admin={isAdmin}/>
      } />
      <Route path="/item/:itemId/edit" element={
          <ItemEdit new={false}/>
      } />
      <Route path="/item/new" element={
          <ItemEdit new={true}/>
      } />
      <Route path="*" element={<div>Pagina non trovata</div>} />
    </Routes>
    <Footer user={user}/>
    </Router>
  );
}

export default App;
