import {useEffect, useState, useRef, useCallback, useImperativeHandle, forwardRef} from 'react';
import './Item.css'
import {
    Link,
    useParams,
    useNavigate
  } from "react-router-dom";
import * as dataAPI from './dataAPI.js'
import more from './more.jpg'
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";


export function ItemList(props) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { categoryId } = useParams();
    function handleDelete(itemId) {
        setData(prevData => prevData.filter(item => item.id !== itemId))
    }
    useEffect(() => {
        async function getItemsByCategory(category) {
            try {
                let items = await dataAPI.getItems(category);
                items.sort((a, b) => (a.description > b.description) ? 1: (a.description < b.description)? -1: 0)
                setData(items);
                setError(null);
            } catch (error) {
                setData(null);
                setError("Can't retrieve items")
            }
            finally {
                setLoading(false);
            }
        }
        getItemsByCategory(categoryId);
    }, [categoryId]);

    return (
        <>
        <div className="album py-5 bg-light">
            {loading && <div>Wait...</div>}
            {error && <div>`Error: ${error}`</div>}
            {data && data.length === 0 && !props.moreItems && <div>Niente in questa categoria ({categoryId})</div>}
            {data && (data.length > 0 || props.moreItems) && (
            <div className="container">
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 card-group">
                    {data.map((item, i) =>
                    <Item key={item.id} id={item.id} description={item.description} available={item.available} url={item.url} admin={props.admin} onDelete={handleDelete}/>
                    )}
                    {props.moreItems && <MoreItems/>}
                </div>
            </div>
            )}
        </div>
        </>
    )
}

function Item(props) {
    async function handleDelete() {
        const confirmDelete = window.confirm(`Sei sicuro di voler eliminare ${props.id}? (Non sarà possibile annullare l'operazione)`);
        if (confirmDelete) {
            await dataAPI.deleteItemById(props.id);
            await props.onDelete(props.id);
        }
    }
    let availableBadge;
    if (props.available === 0)
        availableBadge = <small className="d-inline-flex mb-3 px-2 py-1 fw-semibold text-danger bg-danger bg-opacity-10 border border-danger border-opacity-10 rounded-2">ESAURITO</small>;
    else
        availableBadge = <small className="d-inline-flex mb-3 px-2 py-1 fw-semibold text-success bg-success bg-opacity-10 border border-success border-opacity-10 rounded-2">{props.available}</small>;

    return (
        <div className="col">
            <div className="card shadow-sm">
                <img alt={props.description} src={props.url + "-500.jpg"}/>
              <div className="card-body">
                <p className="card-text">
                    <span className="d-inline-flex mb-3 px-2 py-1 fw-semibold text-secondary bg-secondary bg-opacity-10 border border-secondary border-opacity-10 rounded-2">{props.id}</span>
                    {props.description}
                </p>
                <div className="d-flex justify-content-between align-items-center">
                  <span className="text-end">Disponibili: {availableBadge}</span>
                  {props.admin && (
                    <>
                    <Link to={"/item/" + props.id + "/edit"}>
                        <button type="button" className="btn btn-outline-danger">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pen" viewBox="0 0 16 16">
                                <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
                            </svg>
                        </button>
                    </Link>
                    <button onClick={handleDelete} type="button" className="btn btn-outline-danger">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z"/>
                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z"/>
                        </svg>
                    </button>
                </>
                  )}
                </div>
              </div>
            </div>
        </div>
    )
}

function MoreItems(props) {
    return (
        <div className="col">
            <div className="card shadow-sm">
                <img alt="altri disponibili" src={more}/>
              <div className="card-body">
                <p className="card-text">
                    Altre creazioni in arrivo: torna nei prossimi giorni per scoprirle.
                </p>
              </div>
            </div>
        </div>
    )
}
export function ItemEdit(props) {
    const {itemId} = useParams();

    const cropperRef = useRef(null);
    const itemIdRef = useRef(null);
    const descriptionRef = useRef(null);
    const availableRef = useRef(null);
    const categorySelectorRef = useRef(null);
    const category2SelectorRef = useRef(null);
    const imageRef = useRef(null);

    const [itemSaving, setItemSaving] = useState(false);
    const [itemSavingError, setItemSavingError] = useState(null);
    const [image, setImage] = useState(null);

    let navigate = useNavigate();

    useEffect(() => {
        async function getItemById(itemId) {
            try {
                if (itemId) {
                    let item = await dataAPI.getItemById(itemId);
                    itemIdRef.current.value = item.id;
                    descriptionRef.current.value = item.description || "";
                    descriptionRef.current.disabled = false;
                    availableRef.current.value = (item.available === null || item.available === undefined) ? 1: item.available ;
                    availableRef.current.disabled = false;
                    imageRef.current.src = item.url + "-500.jpg";
                    imageRef.current.alt = item.description || "";
                    categorySelectorRef.current.setSelectedCategory(item.categoryId || "");
                    category2SelectorRef.current.setSelectedCategory(item.categoryId2 || "");
                }
            } catch (error) {
                console.error("Can't retrieve item " + itemId);
            }
        }
        getItemById(itemId);
    }, [itemId]);

    const itemSave = useCallback(
        async (e) => {
            e.preventDefault();
            setItemSaving(true);
            try {
                let item = {
                    id: itemIdRef.current.value,
                    description: descriptionRef.current.value,
                    available: availableRef.current.value,
                    categoryId: categorySelectorRef.current.getSelectedCategory(),
                    categoryId2: category2SelectorRef.current.getSelectedCategory()
                }
                if (props.new) {
                    item.imageData = cropperRef.current.cropper.getCroppedCanvas().toDataURL('image/jpeg', 1.0);
                    await dataAPI.createItem(item);
                }
                else
                    await dataAPI.updateItem(item);
                navigate("/");
            } catch (error) {
                setItemSavingError("Error saving item: " + error);
            }
            finally {
                setItemSaving(false);
            }
        }, [navigate, props.new]
    )

    return (
        <div>
            <form onSubmit={itemSave}>
                <label htmlFor="code" className="form-label">Codice</label>
                <input
                    type="text"
                    className="form-control"
                    id="code"
                    disabled={!props.new}
                    ref={itemIdRef}
                    pattern="[A-Za-z0-9]+"
                    />
                <label htmlFor="description" className="form-label">Descrizione</label>
                <input 
                    type="text"
                    className="form-control"
                    id="description"
                    ref={descriptionRef}
                />
                <label htmlFor="available" className="form-label">Disponibili</label>
                <input
                    type="number"
                    min="0"
                    className="form-control"
                    id="available"
                    ref={availableRef}
                />
                <SelectCategory ref={categorySelectorRef} name="Categoria"/>
                <SelectCategory ref={category2SelectorRef} name="Categoria2"/>
                {
                    props.new &&
                    <>
                        <input
                            type="file"
                            accept="image/jpeg"
                            onChange={e => {
                                setImage(URL.createObjectURL(e.target.files[0]));
                            }}
                        />
                        <Cropper
                            src={image}
                            style={{ maxHeight: 400, maxWidth: 640, width: "100%" }}
                            // Cropper.js options
                            dragMode="move"
                            aspectRatio={1}
                            autoCropArea={0.95}
                            restore={false}
                            guides={false}
                            center={false}
                            highlight={false}
                            cropBoxMovable={false}
                            cropBoxResizable={true}
                            toggleDragModeOnDblclick={false}
                            ref={cropperRef}
                        />
                    </>
                }
                {
                    !props.new &&
                    <div>
                        <img ref={imageRef} alt=""/>
                    </div>
                }
                <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={itemSaving}
                >
                    {itemSaving && "Sto salvando"}
                    {!itemSaving && props.new && "Crea"}
                    {!itemSaving && !props.new && "Salva"}
                </button>
                {itemSavingError && <div>Error: {itemSavingError}</div>}
            </form>
        </div>
    );
}

const SelectCategory = forwardRef((props, ref) => {
    const [categories, setCategories] = useState([]);
    const selectRef = useRef(null);
    const [selectedCategory, setSelectedCategory] = useState("");

    useEffect(() => {
        async function getCategories() {
            try {
                setCategories(await dataAPI.getCategories());
            } catch (error) {
                console.error("Error in getting categories: " + error);
            }
        }
        getCategories();
    }, []);

    useEffect(() => {
        selectRef.current.value = selectedCategory;
        selectRef.current.disabled = false;
    }, [categories, selectedCategory]);

    useImperativeHandle(ref, () => ({
        setSelectedCategory: (s) => setSelectedCategory(s),
        getSelectedCategory: () => selectRef.current.value
    }));

    return (
        <>
        <label htmlFor="category-select" className="form-label">{props.name}</label>
        <select 
            className="form-select"
            id="category-select"
            defaultValue={selectedCategory}
            ref={selectRef}
            disabled={true}
        >
            <option value="">Scegli una categoria</option>
            {categories.map((category) => {
                return <option id={category.id} key={category.id} value={category.id}>{category.name}</option>
                })
            }
        </select>
        </>
    );
});