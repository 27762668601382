import {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {ItemList} from './Item.js'
import * as dataAPI from './dataAPI.js'

export function CategoryContent(props) {
    const [category, setCategory] = useState(null);
    const [categoryLoading, setCategoryLoading] = useState(true);
    const [categoryError, setCategoryError] = useState(null);
    const {categoryId} = useParams();

    useEffect(() => {
        async function getCategoryById(categoryId) {
            try {
                let category = await dataAPI.getCategoryById(categoryId);
                setCategory(category);
                setCategoryError(null);
            } catch (error) {
                setCategory(null);
                setCategoryError("Can't retrieve category" + categoryId);
            }
            finally {
                setCategoryLoading(false);
            }
        }
        getCategoryById(categoryId);
   }, [categoryId]);

   return (
    <>
        {categoryLoading && <div>Wait...</div>}
        {categoryError && <div>`Error: ${categoryError}`</div>}
        {category && <>
            <CategoryHeader forCategory={category}/>
            <ItemList admin={props.admin} forCategoryId={category.id} moreItems={category.moreItems}/>
        </>
   }
    </>
   )
}

export function CategoryList(props) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        async function getCategories() {
            try {
                let categories = await dataAPI.getCategories();
                setData(categories);
                setError(null);
            } catch (error) {
                setData(null);
                setError("Can't retrieve categories")
            }
            finally {
                setLoading(false);
            }
        }
        getCategories();
   }, []);

    return (
        <>
            {props.admin && (
            <div>
                <Link to={"/item/new"}>
                <button className="btn btn-danger btn-lg btn-circle float-end">+</button>
                </Link>
            </div>
            )}
        <div className="container">
            <div className="row row-cols-1 row-cols-sm-3 row-cols-md-3 g-3">
                {loading && <div>Wait...</div>}
                {error && <div>`Error: ${error}`</div>}
                {data && data.map((category, i) => {
                    return <Category key={category.id} id={category.id} name={category.name} image={category.sampleImageUrl}/>
                }
                )}
            </div>
        </div>
        </>
    )
}

function Category(props) {
    return <div className="card shadow-sm">
        <Link to={"/category/" + props.id} className="nav-link active">
            <div className="row no-gutters align-items-center">
                <div className="col-4">
                    <img
                    alt={props.name}
                    src={props.image + "-150.jpg"}
                    className="card-img"
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src="/missing.jpeg";
                    }}/>
                </div>
                <div className='col-8 align-self-center'>
                    <div className="card-body">
                        {props.name}
                    </div>
                </div>
            </div>
        </Link>
    </div>
}
export function CategoryHeader(props) {
    return (
        <div className="container">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{props.forCategory.name}</li>
                </ol>
            </nav>
        </div>
    )
}