function Footer({user}) {
    return (
        <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
            <div className="col-md-12 d-flex align-items-center justify-content-end mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
            {!user && (
                <a className="text-decoration-none link-dark" href={`/.auth/login/aad?post_login_redirect_uri=/`}>Login</a>
            )}
            {user && (
                <>
                    <span style={{margin:'1em'}}>{user && user?.userDetails}</span>
                    <a className="text-decoration-none link-dark" href={`/.auth/logout?post_logout_redirect_uri=/`}>
                        Logout
                    </a>
                </>
            )}
            </div>
        </footer>
    )
}

export default Footer;