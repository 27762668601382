export async function getCategories() {
    let categories = await (await fetch("/api/categories")).json();
    return categories.sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
    });
}

export async function getCategoryById(categoryId) {
    return await (await fetch("/api/categories/" + encodeURIComponent(categoryId))).json();
}

export async function getItems(category) {
    let items = await (await fetch("/api/items?" + new URLSearchParams({
        categoryId: category
    }))).json();
    return items.sort((a, b) => b.available - a.available);
}

export async function getItemById(itemId) {
    return await (await fetch("/api/items/" + encodeURIComponent(itemId))).json();
}

export async function updateItem(item) {
    return await (await fetch("/api/items/" + encodeURIComponent(item.id), {
        method: "PUT",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(item)
    })).json();
}

export async function createItem(item) {
    let response = await fetch("/api/items", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(item)
    });

    if (!response.headers.get("content-type").startsWith("application/json"))
        throw new Error("Invalid content type in response");
    let result = await response.json();
    if (result.error !== 0)
        throw result.message;
    return result;

}

export async function deleteItemById(itemId) {
    return await (await fetch("/api/items/" + encodeURIComponent(itemId), {
        method: "DELETE",
    })).json();
}